#pricing-page {
  .container {

    max-width: 960px;
  }

  .pricing-header {
    max-width: 700px;
  }
}
