@media print {
  .page {
    page-break-after: always;
  }
}

.page {
  //display: flex;
  //flex-direction: column;
  //
  //height: 100%;
  //width: 100%;
  //
  //padding: 2%;

  .content {
    //display: flex;
    //flex-direction: row;
    //justify-content: space-between;
    //
    //height: 100%;
    //width: 100%;

    .rounds {
      //display: flex;
      //flex-direction: column;
      //justify-content: space-between;
      //
      //height: 100%;
      //width: 100%;

      .roundRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        height: 48%;

        .round {
          height: 100%;
          width: 48%;

          border: 1px solid black;

          padding: 6px;
        }
      }
    }

    .right-notes {
      height: 100%;
      width: 30%;

      border: 1px solid black;

      margin-left: 2%;

      text-align: center;
    }

    .bottom-notes {
      height: 48%;

      border: 1px solid black;

      text-align: center;
    }
  }

  .footer {
    display: flex;


  }
}
