$primary: #F0544F;
$secondary: #3A6EA5;
$danger: #D81E5B;
$warning: #F7F052;
$success: #5C8001;
$info: #3772FF;
$dark: $secondary;

$link-color: $info;
$emphasized-link-hover-darken-percentage: 25%;
$border-radius: .5rem;
$border-radius-lg: .6rem;
$border-radius-sm: .4rem;
$print-page-size: letter;
$body-bg: #f8f9fa;
