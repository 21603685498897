@import './../../../../_variables.scss';

#team {
  background: #eee !important;

  .card {
    border: none;
    background: #ffffff;
  }

  .btn-primary:hover,
  .btn-primary:focus {
    background-color: $secondary;
    border-color: $secondary;
    box-shadow: none;
    outline: none;
  }

  .btn-primary {
    color: #fff;
    background-color: $secondary;
    border-color: $secondary;
  }

  section {
    padding: 60px 0;

    .section-title {
      text-align: center;
      color: $secondary;
      margin-bottom: 50px;
      text-transform: uppercase;
    }
  }

  .image-flip:hover .backside,
  .image-flip.hover .backside {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
    border-radius: .25rem;
  }

  .image-flip:hover .frontside,
  .image-flip.hover .frontside {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }

  .mainflip {
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -ms-transition: 1s;
    -moz-transition: 1s;
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
    position: relative;
  }

  .frontside {
    position: relative;
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    z-index: 2;
    margin-bottom: 30px;

    .card {
      .card-body {
        img {
          width: 120px;
          height: 120px;
          border-radius: 50%;
        }
      }
    }
  }

  .backside {
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    -moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);

    .card {
      a {
        font-size: 18px;
        color: $secondary !important;
      }
    }
  }

  .frontside,
  .backside {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -moz-transition: 1s;
    -moz-transform-style: preserve-3d;
    -o-transition: 1s;
    -o-transform-style: preserve-3d;
    -ms-transition: 1s;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
  }

  .frontside .card,
  .backside .card {
    min-height: 312px;
  }

  .frontside .card .card-title,
  .backside .card .card-title {
    color: $secondary !important;
  }
}